
import { Options, Vue, prop } from "vue-class-component";
import VueApexCharts from "vue3-apexcharts";

interface Series {
  name: string;
  data: number[];
}
class Props {
  series = prop<Series[]>({
    default: [],
    type: Array
  });
  height = prop<number>({
    default: 350,
    type: Number
  });
  categories = prop<string[]>({
    default: [],
    type: Array
  });
  color = prop<string[]>({
    default: [],
    type: Array
  });
  rounded = prop<boolean>({
    default: true,
    type: Boolean
  });
  dataLabel = prop<boolean>({
    default: true,
    type: Boolean
  });
  stacked = prop<boolean>({
    default: true,
    type: Boolean
  });
  barHeight = prop<string>({
    default: "50%",
    type: String
  });
  labelBarPosition = prop<string>({
    default: "center" || "bottom" || "top",
    type: String
  });
}

@Options({
  components: {
    VueApexCharts
  }
})
export default class BarCharts extends Vue.with(Props) {
  barPosition: { [x: string]: any } = {
    top: {
      offsetX: 20,
      offsetY: -1,
      colors: ["#4d4d4d"]
    },
    center: {
      offsetX: 0,
      offsetY: 0
    },
    bottom: {
      offsetX: 0,
      offsetY: 0
    }
  };
  chartOptions = {
    chart: {
      fontFamily: "Poppins",
      type: "bar",
      stacked: this.stacked,
      toolbar: false
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: this.barHeight,
        borderRadius: this.rounded ? 8 : 0,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        dataLabels: {
          position: this.labelBarPosition
        }
      }
    },
    dataLabels: {
      ...this.barPosition[this.labelBarPosition],
      enabled: this.dataLabel,
      style: {
        ...this.barPosition[this.labelBarPosition],
        fontSize: "10px",
        fontWeight: 400
      }
    },
    xaxis: {
      categories: this.categories,
      labels: {
        show: true,
        style: {
          fontSize: "12px",
          fontFamily: "Poppins"
        }
      }
    },
    colors: Array.from({ length: this.series.length }, () => this.color).flat(),
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      },
      strokeDashArray: 5,
      padding: {
        right: 25,
        left: 15
      }
    },
    legend: {
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "Poppins",
      offsetY: 0,
      markers: {
        width: 10,
        height: 10,
        radius: 20,
        shape: "square",
        size: 8
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0
      },
      showForSingleSeries: true
    },
    yaxis: {
      categories: this.categories,
      labels: {
        show: true,
        trim: true,
        maxWidth: "30%",
        style: {
          fontSize: "12px",
          fontFamily: "Poppins"
        }
      }
    },
    fill: {
      colors: Array.from(
        { length: this.series.length },
        () => this.color
      ).flat()
    },
    stroke: {
      width: !this.stacked ? 2 : 0,
      colors: ["#fff"],
      fill: {
        type: "solid",
        opacity: 0.85,
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: []
        }
      }
    },
    tooltip: {
      y: {
        formatter: (val: number) => val.toFixed()
      }
    }
  };
}
